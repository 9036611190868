import { t } from 'ttag'
import Swiper from 'swiper' // eslint-disable-line

import { international } from 'compact/shared/general/region.js'
import enhancedFetch from 'compact/shared/general/enhanced_fetch.js'
import { extractLocationInfo } from 'compact/shared/general/utils.js'

/**
 * Based on `autocomplete_component.js` from the V1. Main changes:
 * - project search results are rendered on the backend and the HTML is being returned
 * - removed all references to `listings` array or `listingPath`
 * - added a few extra x-refs for managing projects section & container
 * - `expand` and `fold` methods for managing the search results
 * - fetching popular projects and places
 * - other adjustments to make it work with the new design
 */
window.components.projects.autocomplete = ({
  url,
  listingsPath,
  popularPlacesPath,
  popularProjectsPath,
  searchString = '',
  searchInputHidden = true
}
) => {
  return {
    url,
    listingsPath,
    popularPlacesPath,
    popularProjectsPath,
    loading: false,
    hasProjects: false,
    places: [],
    expanded: false,
    searchString,
    searchInputHidden,
    autocompleteService: new google.maps.places.AutocompleteService(),
    placesService: new google.maps.places.PlacesService(document.createElement('div')),
    activeSuggestionIdx: -1,
    forceHoverFirstElement: false,
    latestProjects: '',
    popularPlaces: [],
    init() {
      this.$refs.autocompleteForm.addEventListener('submit', event => {
        this.noSelectionSubmit()
        event.preventDefault()
        return false
      })
    },
    handleResize() {
      if (this.isMobile()) {
        this.swiper?.destroy()
        this.swiper = undefined
      } else if (!this.swiper) {
        this.initializeSwiper()
      }
    },
    setAutoFocusState() {
      const userAgent = navigator.userAgent

      if(/iPad|iPhone|iPod/i.test(userAgent) || this.isMobile()) return

      this.$refs.autocompleteInput.focus()
    },
    expand() {
      if (this.expanded) return
      this.expanded = true
      window.dispatchEvent(new CustomEvent('show-search-modal-background'))

      // the search input must be visible before it can be focused
      this.$nextTick(() => {
        this.setAutoFocusState()
      })

      if (this.searchString.length > 0) return

      if (!this.hasProjects) {
        this.loading = true
        enhancedFetch(this.popularProjectsPath, {})
          .then(resp => resp.text())
          .then(results => {
            this.handleProjectResults(results)
          })
          .catch(() => { })
      }

      if (this.places.length === 0) {
        enhancedFetch(this.popularPlacesPath, {
          headers: {
            'Accept': 'application/json'
          }
        })
          .then(resp => resp.json())
          .then(results => {
            this.popularPlaces = results
            this.places = this.popularPlaces
            if (this.places.length > 0) {
              this.$refs.placesHeader.innerHTML = t`Popular places`
            } else {
              this.$refs.placesHeader.innerHTML = t`Places`

            }
          })
          .catch(() => { })
      }
    },
    toggle() {
      if (this.expanded) {
        this.fold()
      } else {
        this.expand()
      }
    },
    getMatches(query) {
      this.loading = true
      const params = this.getSearchParams(query)
      enhancedFetch(`${this.url}?${params}`, {})
        .then(resp => resp.text())
        .then(results => {
          this.handleProjectResults(results)
        })
        .catch(() => { })
    },
    doSearch(event) {
      if(event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        return
      }
      if (!this.searchString || this.searchString.length === 0) {
        this.handleProjectResults(this.popularProjects)
        this.places = this.popularPlaces
        return
      }
      if (this.searchString.length < 2) { return }

      this.forceHoverFirstElement = true
      this.items = []
      this.activeSuggestionIdx = 0
      this.getMatches(this.searchString)
      this.autocompleteService.getPlacePredictions({
        input: this.searchString,
        componentRestrictions: international ? null : { country: ['no'] },
      }, predictions => {
        this.$refs.placesHeader.innerHTML = t`Places`
        if (!predictions) {
          this.places = []
          return
        }
        this.places = predictions.map(place => {
          return Object.assign({}, place, {
            id: place.place_id,
            type: 'place',
            administrative_types: place.types,
            description: extractLocationInfo(place.description)
          })
        })
      })
    },
    noSelectionSubmit() {
      if (!this.hasSuggestions() && this.searchString.length > 0) { return }

      if (!this.hasSuggestions()) {
        window.location.href = this.listingsPath
      }

      if (this.places.length > 0) {
        this.goToPlace(this.places[0].place_id)
      }
    },
    selectSuggestion(event) {
      if (!(event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter')) {
        return
      }
      const suggestionCount = this.places.length
      event.preventDefault()
      event.stopPropagation()

      if(event.key === 'ArrowUp') {
        if (this.activeSuggestionIdx === -1) { return }

        this.activeSuggestionIdx--
      } else if (event.key === 'ArrowDown') {
        if (this.activeSuggestionIdx === suggestionCount - 1) { return }

        this.activeSuggestionIdx++
      } else {
        if (this.activeSuggestionIdx === -1) {
          this.noSelectionSubmit()
          return
        }

        const suggestion = this.places[this.activeSuggestionIdx]
        if (!suggestion) { return }
        this.goToPlace(suggestion.id)
        return
      }
    },
    onHover(){
      if (!this.forceHoverFirstElement) return

      this.forceHoverFirstElement = false
    },
    goToSuggestion({ target }) {
      const resultEl = target.closest('.section-result')

      const suggestionId = resultEl.getAttribute('data-id')
      const suggestionType = resultEl.getAttribute('data-type')

      if (suggestionType === 'popular') {
        this.goToPopularPlace(suggestionId)
      } else {
        this.goToPlace(suggestionId)
      }
    },
    goToPlace(placeId) {
      const place = this.places.find(p => p.place_id === placeId)
      this.placesService.getDetails(
        { placeId, fields: ['name', 'geometry'] },
        result => {
          const location = result?.geometry?.location
          const center = [location.lat(), location.lng()]
            .map(c => c.toFixed(5))
            .join(',')

          const filters = {
            center: center,
            placeTypes: place.administrative_types.join(',')
          }

          this.redirectToPlace(place.description, filters)
        }
      )
    },
    // Popular places comes from our backend so no need to call Google API for details
    goToPopularPlace(placeId) {
      const place = this.places.find(p => p.id === parseInt(placeId))

      this.redirectToPlace(place.description, { mapBounds: place.map_bounds })
    },
    redirectToPlace(description, filters = { center: null, placeTypes: null, mapBounds: null }) {
      const redirectUrl = new URL(`${window.location.origin}/${this.listingsPath}`)
      redirectUrl.searchParams.set('place', description)

      if (filters.center) {
        redirectUrl.searchParams.set('filters[center]', filters.center)
      }
      if (filters.placeTypes) {
        redirectUrl.searchParams.set('filters[place_types]', filters.placeTypes)
      }
      if (filters.mapBounds) {
        redirectUrl.searchParams.set('filters[map_bounds]', filters.mapBounds)
      }

      window.location.href = redirectUrl
    },
    hasSuggestions() {
      return this.places.length > 0 || this.hasProjects
    },
    fold() {
      this.expanded = false
      window.dispatchEvent(new CustomEvent('hide-search-modal-background'))
    },
    clearListings() {
      this.hasProjects = false
      this.places = []
    },
    clearSearchString() {
      this.searchString = ''
    },
    clearAll() {
      this.clearListings()
      this.clearSearchString()
      this.fold()
    },
    getSearchParams(query) {
      const params = new URLSearchParams()
      params.set('filters[query]', query)
      params.set('filters[used]', false)
      params.set('per_page', 5)

      return params
    },
    isMobile() {
      // Why 767 and not 768 (like configured in tailwind.config.js)? Because it's inclusive.
      // At 768px, the layout for autocomplete results renders in desktop mode.
      // Thus for 768px, this method must return false
      // to have swiper initialized to work with the desktop layout.
      const mediaQuery = window.matchMedia('(max-width: 767px)')
      return mediaQuery.matches
    },
    handleProjectResults(results) {
      this.loading = false

      if (this.searchString.length > 0) {
        this.$refs.projectsHeader.innerHTML = t`Projects that match «${this.searchString}»`
      } else {
        this.$refs.projectsHeader.innerHTML = t`Latest projects`
        this.popularProjects = results
      }

      if (results === '') {
        this.$refs.projectSearchResultsContainer.innerHTML = ''
        this.hasProjects = false
        return
      }

      this.hasProjects = true
      this.$refs.projectSearchResultsContainer.innerHTML = results

      if (!this.isMobile()) {
        this.swiper?.destroy()
        this.initializeSwiper()
      }
    },
    initializeSwiper() {
      this.swiper = new Swiper(this.$refs.swiper, {
        loop: false,
        slidesPerView: 'auto',
        centeredSlides: false,
        watchOverflow: true,
        spaceBetween: 24,
      })
    }
  }
}
