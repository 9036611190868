import { isMobile } from 'compact/shared/general/utils.js'

window.components.header = () => {
  return {
    visibleContainer: false,
    init() {
      this.$watch('visibleContainer', value => this.blockBody(value))

      if (isMobile(true)) {
        const maxHeight = window.innerHeight - document.getElementById('navigation').offsetHeight
        this.$refs.notificationsContainer.style.maxHeight = `${maxHeight}px`
      }
    },
    toggle(container) {
      if (this.visibleContainer !== container) {
        this.visibleContainer = container
      }
      else {
        this.visibleContainer = this.visibleContainer ? false : container
      }

      this.$store.overlays.expanded = !!this.visibleContainer
    },
    isOpen(container) {
      return this.visibleContainer === container
    },
    blockBody(value) {
      if (!isMobile()) return

      if (value) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  }
}
