import { t } from 'ttag'

import enhancedFetch from 'compact/shared/general/enhanced_fetch.js'

window.components.modals.authentication = ({
  checkEmailPath,
  loginPath,
  resetPath,
  registrationPath,
  defaultTitle,
  autoStart
}) => {
  const emptyUserData = () => {
    return {
      email: '',
      accept_privacy_policy: true,
      showPassword: false
    }
  }

  return {
    formValidation: window.components.formValidation(),
    formError: false,
    checkEmailPath: checkEmailPath,
    loginVisible: false,
    loginPath: loginPath,
    loginData: emptyUserData(),
    resetVisible: false,
    resetPath: resetPath,
    resetData: emptyUserData(),
    resetSent: false,
    resetSentVisible: false,
    registrationVisible: false,
    registrationPath: registrationPath,
    registrationCompleted: false,
    createdVisible: false,
    formErrorMessage: null,
    loading: false,
    redirectUrl: false,
    title: defaultTitle,
    visible() {
      return this.loginVisible || this.resetVisible || this.registrationVisible
        || this.createdVisible || this.resetSentVisible
    },
    init() {
      this.formValidation.initForm(this.$refs.loginForm)
      this.formValidation.initForm(this.$refs.resetForm)
      this.formValidation.initForm(this.$refs.registrationForm)

      window.addEventListener('popstate', () => {
        if (this.registrationVisible || this.resetVisible) {
          this.showLogin()
        }
        else {
          this.close()
        }
      })

      this.$el.style.height = `${window.innerHeight}px`

      if (autoStart) this.open({})
    },
    open({ title }) {
      if (!this.visible()) {
        this.loginVisible = true
        document.querySelector('body').classList.add('overflow-hidden')
      }
      if (title) {
        this.title = title
      }
      else {
        this.title = defaultTitle
      }
    },
    close() {
      this.hideAll()
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    hideAll() {
      this.loginVisible = false
      this.resetVisible = false
      this.registrationVisible = false

      this.formError = false
      this.formErrorMessage = null
    },
    showLogin() {
      this.hideAll()
      this.loginVisible = true
    },
    payload(data, skipFollowRedirect = true) {
      return {
        body: JSON.stringify({ user: data }),
        skipFollowRedirect: skipFollowRedirect,
        skipAuthCheck: true,
        method: 'POST'
      }
    },
    login() {
      this.$refs.loginForm.reportValidity()
      if (!this.$refs.loginForm.checkValidity()) return

      this.loading = true
      const url = `${this.checkEmailPath}?email=${this.loginData.email}`

      return enhancedFetch(url, { method: 'GET' }).then((response) => {
        this.loading = false
        if (response.status === 404) {
          this.createUser()
        } else if (response.status === 200) {
          this.showRegistration()
        }
      })
    },
    showResetPassword() {
      this.hideAll()
      this.resetVisible = true
    },
    reset() {
      this.$refs.resetForm.reportValidity()
      if (!this.$refs.resetForm.checkValidity()) return

      this.loading = true
      const payload = this.payload(this.resetData, true)

      return enhancedFetch(this.resetPath, payload).then((response) => {
        this.loading = false

        if (response.status === 401) {
          this.formError = true
        }
        else {
          this.showResetSent()
        }
      })
    },
    showResetSent() {
      this.hideAll()
      this.resetSentVisible = true
    },
    showRegistration() {
      this.hideAll()
      this.registrationVisible = true
    },
    createUser() {
      this.$refs.loginForm.reportValidity()
      if (!this.$refs.loginForm.checkValidity()) return

      this.loading = true
      const payload = this.payload(this.loginData, true)

      return enhancedFetch(this.registrationPath, payload).then((response) => {
        if (response.status === 422) {
          response.json().then((resp) => {
            this.formErrorMessage = resp.errors.join('</br>')
          })
          this.loading = false
        }
        else {
          this.loading = false
          this.loginVisible = false
          this.redirectUrl = response.url
          this.createdVisible = true
        }
      })
    },
    register() {
      this.$refs.loginForm.reportValidity()
      if (!this.$refs.loginForm.checkValidity()) return

      this.loading = true
      return enhancedFetch(this.loginPath, this.payload(this.loginData)).then((response) => {
        if (response.status === 401) {
          this.formError = true
          this.formErrorMessage = t`Invalid password`
          this.loading = false
        }
        else {
          this.redirectUrl = response.url
          this.refresh()
        }
      })
    },
    refresh() {
      if (this.redirectUrl && (document.location.href !== this.redirectUrl )) {
        document.location.href = this.redirectUrl
      }
      else {
        document.location.reload()
      }
    }
  }
}
