import enhancedFetch from 'compact/shared/general/enhanced_fetch'
import errorStore from 'compact/shared/general/error_store'

window.components.modals.setPassword = ({
  setPasswordApiEndpoint,
  userSetupApiEndpoint,
  resetPasswordToken,
  isOpen,
  step
}) => {
  return {
    errorStore: errorStore({}, ['password', 'password_confirmation']),
    formValidation: window.components.formValidation(),
    focusedInput: '',
    isOpen: isOpen,
    step: step,
    passwordFormData: {
      password: '',
      password_confirmation: '',
      reset_password_token: resetPasswordToken,
    },
    showPassword: false,
    showPasswordConfirmation: false,
    userFormData: {
      first_name: '',
      last_name: '',
      phone_number: '',
      address: '',
      birthyear: '',
      occupation: ''
    },
    loading: false,
    init() {
      this.$store.overlays.modalOpened()
      this.formValidation.initForm(this.$refs.profileEditForm)
    },
    close() {
      this.isOpen = false
      // clear params ('show_complete_profile') from the URL without refreshing the page
      window.history.replaceState(null, null, window.location.pathname)
      this.$store.overlays.modalClosed()
    },
    onSetBirthyear(event) {
      this.userFormData.birthyear = event.detail.detail.value
    },
    onInput() {
      this.$el.classList.remove('empty', 'invalid')
    },
    onFocus(field) {
      this.focusedInput = field
      this.errorStore.removeErrors(field)
    },
    setPassword() {
      if (this.loading) return

      this.loading = true
      enhancedFetch(
        setPasswordApiEndpoint,
        {
          body: JSON.stringify({
            user: this.passwordFormData
          }),
          method: 'PUT',
        }
      ).then((response) => {
        if (response.status === 422) {
          return response.json()
        } else if (response.status === 200) {
          this.step = 2
        }
      }).then((response) => {
        if (response) this.errorStore.setRawErrors(response.errors)
      })
      this.loading = false
    },
    setupUser() {
      if (this.loading) return

      this.$refs.profileEditForm.reportValidity()
      if (!this.$refs.profileEditForm.checkValidity()) return

      this.loading = true
      enhancedFetch(
        userSetupApiEndpoint,
        {
          body: JSON.stringify({
            user: this.userFormData
          }),
          method: 'PATCH',
        }
      ).then((response) => {
        if (response.status === 200) this.step = 3
      })
      this.loading = false
    },
    finish() {
      this.close()
      window.location.reload()
    }
  }
}
