window.components.select = ({
  items,
  placeholder,
  value
}) => {
  return {
    placeholder,
    value,
    items: JSON.parse(items),
    isOpen: false,
    close() {
      this.isOpen = false
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
    setValue(value) {
      this.value = value
      this.close()
    },
    currentValue() {
      return this.value ? this.value : this.placeholder
    }
  }
}
