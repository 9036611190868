window.components = {
  modals: {},
  news: {},
  profile: {},
  projects: {},
  users: {},
  pages: {},
  offers: {}
}

const components = require.context('compact/default/alpinejs/components', true, /_component\.js$/)
components.keys().forEach(components)
