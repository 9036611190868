window.components.autocomplete = ({ openByDefault }) => {
  return {
    openByDefault: openByDefault,
    isOpen: openByDefault,
    isExpanded: false,
    initialTopPosition: 0,
    init() {
      this.initialTopPosition = parseInt(getComputedStyle(this.$el).top.split('px')[0])
      this.realign()
    },
    toggle() {
      if (this.isOpen) this.isOpen = this.openByDefault
      else this.isOpen = true

      const event = new CustomEvent('toggle-search-modal')
      window.dispatchEvent(event)
    },
    showSearchModalBackground() {
      this.$store.overlays.expanded = true
      this.$store.overlays.searchExpanded = true

      this.isExpanded = true
      window.dispatchEvent(new CustomEvent('close-first-search-banner'))
      this.$store.overlays.modalOpened()
    },
    hideSearchModalBackground() {
      this.$store.overlays.expanded = false
      this.$store.overlays.searchExpanded = false

      this.isExpanded = false
      window.dispatchEvent(new CustomEvent('open-first-search-banner'))
      this.isOpen = this.openByDefault
      this.$store.overlays.modalClosed()
    },
    realign() {
      let adjustedTopPosition = this.initialTopPosition

      const firstSearchBanner = document.getElementById('first-saved-search-banner')
      if (firstSearchBanner) {
        adjustedTopPosition = adjustedTopPosition + firstSearchBanner.offsetHeight
      }

      this.$el.style.top = `${adjustedTopPosition}px`
    }
  }
}
