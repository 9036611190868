import { t } from 'ttag'

import enhancedFetch from 'compact/shared/general/enhanced_fetch.js'

window.components.projects.favouriteSwitch = ({ isFavourite, url, projectName }) => {
  return {
    url: url,
    isFavourite: isFavourite,
    projectName: projectName,
    toggle() {
      enhancedFetch(
        this.url,
        {
          method: 'PUT',
          loginPayload: { title: t`Save your favorite projects.` }
        }
      )
        .then(response => response.json())
        .then(response => {
          this.isFavourite = response.is_favourite
          if (this.isFavourite) {
            window.dataLayer.push({
              'event': 'add_to_wishlist',
              'projectName': this.projectName,
              'pagePath': window.location.pathname
            })
          }
        })
    }
  }
}
