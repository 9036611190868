const ErrorStore = (rawErrors, knownFields) => {
  return {
    rawErrors: rawErrors || {},
    knownFields: knownFields || [],
    setRawErrors(rawErrors) {
      this.rawErrors = rawErrors || {}
    },
    firstError(field) {
      const errors = this.errors(field)
      return errors && errors[0]
    },
    errors(field) {
      return field ? this.rawErrors[field] : this.genericErrors()
    },
    hasErrors(field) {
      return !!this.errors(field)
    },
    genericFields() {
      return Object.keys(this.rawErrors).filter(field => !this.knownFields.includes(field))
    },
    genericErrors() {
      return this.genericFields().map(field => this.rawErrors[field]).flat()
    },
    removeErrors(field) {
      delete this.rawErrors[field]
    }
  }
}

export default ErrorStore
