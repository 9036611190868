import Swiper from 'swiper' // eslint-disable-line

window.components.horizontalCarousel = () => {
  const largeScreenWidth = 748
  const mediumScreenWidth = 480

  return {
    swiper: null,
    currentSlideIndex: 0,
    totalSlides: 0,
    init() {
      this.swiper = new Swiper(this.$refs.swiper, {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: false,
        breakpoints: {
          480: {
            slidesPerView: 2
          },
          748: {
            slidesPerView: 3
          }
        }
      })
      const _this = this
      this.totalSlides = this.$refs.swiper.querySelectorAll('.swiper-slide').length
      this.swiper.on('slideChange', function (object) {
        this.currentSlideIndex = object.activeIndex
      })
    },
    slideBack() {
      if (this.currentSlideIndex !== 0) {
        this.currentSlideIndex -= 1
        this.swiper.slidePrev()
      }
    },
    slideForward() {
      if (this.currentSlideIndex < this.findLastSlideIndex()) {
        this.currentSlideIndex += 1
        this.swiper.slideNext()
      }
    },
    findLastSlideIndex() {
      const width = window.innerWidth
      if (width >= largeScreenWidth) { return 3 }
      else if (width >= mediumScreenWidth) { return 4 }
      else return 5
    },
    paginationVisible() {
      return this.totalSlides > this.findLastSlideIndex()
    }
  }
}
